import Vue from "vue";
import VueRouter from "vue-router";
import propertyServices from "../views/propertyServices.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "propertyServices",
    component: propertyServices,
  },
  {
    path: "/repairPage",
    name: "repairPage",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/repairPage.vue"),
  },
  {
    path: "/opinionPage",
    name: "opinionPage",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/opinionPage.vue"),
  },
  {
    path: "/noticePage",
    name: "noticePage",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/noticePage.vue"),
  },
  {
    path: "/submittedPage",
    name: "submittedPage",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/submittedPage.vue"),
  },
  {
    path: "/venueBooking",
    name: "venueBooking",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/venueBooking.vue"),
  },
  {
    path: "/businessPage",
    name: "businessPage",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/businessPage.vue"),
  },
  {
    path: "/partyMasses",
    name: "partyMasses",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/partyMasses.vue"),
  },
  {
    path: "/appointmentSubmit",
    name: "appointmentSubmit",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/appointmentSubmit.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
