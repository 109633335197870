<template>
  <div class="page scrollView">
    <div class="pageTitle">物业服务</div>
    <div class="noticeBox" @click="goNoticePage">
      <img
        :src="toMedia(detail.notice_banner)"
        class="imgBg"
        v-if="detail.notice_banner"
      />
      <div class="itemTitle">公告</div>
      <div class="noticeWord" v-if="detail.notice_title">
        {{ detail.notice_title }}
      </div>
    </div>
    <div class="repairBox" @click="goRepair">
      <img src="../assets/img/repairBg.png" class="imgBg" />
      <div class="itemTitle">报修服务</div>
      <div class="goRepair">
        <span>我要报修</span>
        <img src="../assets/img/goNext.png" class="goNext" />
      </div>
    </div>
    <div class="opinionBox" @click="goOpinion">
      <img src="../assets/img/opinion.png" class="imgBg" />
      <div class="itemTitle">意见反馈</div>
      <div class="goOpinion">
        <span>我要反馈</span>
        <img src="../assets/img/goNext.png" class="goNext" />
      </div>
    </div>
    <p class="serviceTitle">服务电话</p>
    <a
      v-if="detail.service_phone"
      class="serviceBox"
      :href="'tel:' + detail.service_phone"
      >{{ detail.service_phone }}
      {{ detail.service_place ? "（" + detail.service_place + "）" : "" }}</a
    >
    <a
      v-if="detail.service_phone_o"
      class="serviceBox"
      :href="'tel:' + detail.service_phone_o"
      >{{ detail.service_phone_o }}
      {{
        detail.service_place_o ? "（" + detail.service_place_o + "）" : ""
      }}</a
    >
  </div>
</template>

<script>
import { getInfo } from "@/api/base";
import { toMedia } from "@/utils/request";
export default {
  name: "propertyServices",

  data() {
    return {
      detail: {},
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    toMedia,
    getInfo: function () {
      getInfo().then(
        (response) => {
          if (response.code === 200) {
            this.detail = response.data;
            this.$store.dispatch(
              "setNoticeDetail",
              response.data.notice_detail ? response.data.notice_detail : ""
            );
          }
        },
        () => {}
      );
    },
    goNoticePage: function () {
      this.$router.push({
        path: "/noticePage",
      });
    },
    goRepair: function () {
      this.$router.push({
        path: "/repairPage",
      });
    },
    goOpinion: function () {
      this.$router.push({
        path: "/opinionPage",
      });
    },
  },
};
</script>

<style scoped>
.serviceTitle {
  margin-top: 36px;
  font-size: 33px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #404040;
  line-height: 45px;
  text-align: center;
}
.serviceBox {
  display: block;
  margin: 27px auto 0;
  width: 678px;
  height: 82px;
  border-radius: 9px;
  border: 2px solid rgba(56, 56, 56, 0.1);
  line-height: 80px;
  font-size: 27px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #404040;
  text-align: center;
}
.serviceBox:last-child {
  margin-bottom: 100px;
}
.telBox {
  width: 678px;
  height: 82px;
  border-radius: 9px;
  border: 1px solid #f5f5f5;
  font-size: 27px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #404040;
  line-height: 78px;
  text-align: center;
  margin: 22px auto 100px;
}
.telBox a {
  color: #404040;
}

.goNext {
  width: 6px;
  /* height: 20px; */
  margin-left: 5px;
}

.goRepair,
.goOpinion {
  width: 168px;
  height: 62px;
  background: #ebebeb;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 45px;
  margin-top: 76px;
  line-height: 24px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #404040;
}

.noticeWord {
  font-size: 33px;
  font-family: PingFangSC-Regular, PingFang SC;
  /* font-weight: lighter; */
  color: #3e3e3e;
  /* color: #FFFFFF; */
  line-height: 45px;
  /* display: flex; */
  /* flex-direction: column; */
  margin-left: 45px;
  width: 270px;
  height: 135px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.itemTitle {
  padding: 45px 45px 0;
  font-size: 45px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: bold;
  color: #383838;
  line-height: 65px;
}

.imgBg {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.noticeBox,
.repairBox,
.opinionBox {
  width: 678px;
  height: 303px;
  border-radius: 9px;
  margin: 0 auto 36px;
  overflow: hidden;
}
.noticeBox .imgBg {
  top: 50%;
  transform: translateY(-50%);
}

.pageTitle {
  font-size: 36px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: bold;
  color: #383838;
  line-height: 51px;
  text-align: center;
  margin: 43px auto 54px;
}
</style>
