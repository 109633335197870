import axios from "axios";
import { Toast } from "vant";

// const serverUrl = '/'
// const mediaUrl =
// "http://git.local.cn/2021/QiandaoLakeProperty/qiandaolakeproperty-admin/public/";
const serverUrl = "https://case.brintech.cn/php74/qianDaoServer/public/";
const mediaUrl = "https://case.brintech.cn/php74/qianDaoServer/public/";

const service = axios.create({
  baseURL: serverUrl,
  // withCredentials: true,
  timeout: 20000,
});
// 添加请求拦截器
service.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
// 添加响应拦截器
service.interceptors.response.use(
  function (response) {
    if (response.data.code == 200) {
      Toast.clear();
      return response.data;
    } else {
      Toast({
        message: response.data.message,
        forbidClick: true,
        duration: 1500,
      });
    }
  },
  function (error) {
    console.log(error);
    Toast.clear();
  }
);

export function getBaseUrl() {
  return serverUrl;
}

export function toMedia(url) {
  if (!url || url == "" || url == undefined) {
    return null;
  }
  if (url.indexOf("http://") !== -1 || url.indexOf("https://") !== -1) {
    return url;
  }
  return mediaUrl + url;
}

export default service;
