import request from "@/utils/request";

export function getInfo(params) {
  return request({
    url: "/api/v1/setting",
    method: "get",
    params,
  });
}

export function getDict(params) {
  return request({
    url: "/api/v1/dict",
    method: "get",
    params,
  });
}

export function getPlace(params) {
  return request({
    url: "/api/v1/place",
    method: "get",
    params,
  });
}
