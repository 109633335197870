import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    noticeDetail: "",
    appointmentBackups: null,
  },
  mutations: {
    setDetail(state, detail) {
      state.noticeDetail = detail;
    },
    setAppointment(state, data) {
      state.appointmentBackups = data;
    },
  },
  actions: {
    setNoticeDetail({ commit }, name) {
      commit("setDetail", name);
    },
    setAppointmentBackups({ commit }, data) {
      commit("setAppointment", data);
    },
  },
  getters: {
    getNoticeDetail(state) {
      return state.noticeDetail;
    },
    getAppointmentBackups(state) {
      return state.appointmentBackups;
    },
  },
  modules: {},
});
